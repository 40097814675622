import React, { useRef, useEffect } from 'react';

interface TutorialPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const TutorialPopup: React.FC<TutorialPopupProps> = ({ isOpen, onClose }) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div ref={popupRef} className="bg-gray-800 p-8 rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto relative">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-300"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <h2 className="text-3xl font-bold mb-6 text-white text-center">Como Jogar</h2>

        <div className="space-y-8 text-gray-300">
          <div className="bg-gray-700/50 p-6 rounded-lg">
            <p className="text-lg mb-4">
              Descubra o versículo do dia em 4 etapas. A cada tentativa, você receberá dicas visuais para te ajudar a encontrar a resposta correta.
            </p>
            <div className="bg-blue-900/30 p-4 rounded-lg">
              <h4 className="text-lg font-semibold text-blue-400 mb-2">Exemplo de Versículo:</h4>
              <p className="italic">"Porque Deus amou o mundo de tal maneira que deu o seu Filho unigênito, para que todo aquele que nele crê não pereça, mas tenha a vida eterna."</p>
            </div>
          </div>

          {/* Exemplo do Livro */}
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-blue-400">📚 Etapa 1: Livro</h3>
            <div className="bg-gray-700/50 p-4 rounded-lg">
              <p className="mb-4">Ao tentar adivinhar o livro, você receberá informações sobre o grupo, autor, quantidade de capítulos e testamento:</p>
              
              <div className="mb-6">
                <div className="flex items-center space-x-2 mb-4">
                  <input
                    type="text"
                    value="APOCALIPSE"
                    readOnly
                    className="bg-gray-700 text-white px-4 py-2 rounded-lg border border-gray-600 focus:outline-none focus:border-blue-500 w-full"
                  />
                  <button
                    className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg whitespace-nowrap transition-colors duration-200"
                  >
                    Enviar Palpite
                  </button>
                </div>

                <div className="grid grid-cols-5 gap-1 mb-2">
                  <div className="text-center text-xs font-semibold text-gray-300 uppercase">Livro</div>
                  <div className="text-center text-xs font-semibold text-gray-300 uppercase">Grupo</div>
                  <div className="text-center text-xs font-semibold text-gray-300 uppercase">Autor</div>
                  <div className="text-center text-xs font-semibold text-gray-300 uppercase">Capítulos</div>
                  <div className="text-center text-xs font-semibold text-gray-300 uppercase">Testamento</div>
                </div>
                <div className="grid grid-cols-5 gap-1">
                  <div className="bg-red-500 p-2 text-white text-xs text-center rounded-l">Apocalipse</div>
                  <div className="bg-red-500 p-2 text-white text-xs text-center">Revelação</div>
                  <div className="bg-green-500 p-2 text-white text-xs text-center">João</div>
                  <div className="bg-yellow-500 p-2 text-white text-xs text-center flex items-center justify-between">
                    <span>22</span>
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M5 10l7 7 7-7"></path>
                    </svg>
                  </div>
                  <div className="bg-green-500 p-2 text-white text-xs text-center rounded-r">NT</div>
                </div>
                <p className="text-sm text-gray-400 mt-2">
                  🎯 Neste exemplo, o livro correto é João. As cores indicam que:
                </p>
                <ul className="text-sm text-gray-400 list-disc list-inside ml-4">
                  <li>O livro correto não é Apocalipse (vermelho)</li>
                  <li>O livro correto não é Revelação (vermelho)</li>
                  <li>O autor do livro correto também é João (verde)</li>
                  <li>O livro correto tem 21 capítulos, Apocalipse tem 22 (amarelo, próximo do número correto)</li>
                  <li>O livro correto está no Novo Testamento (verde)</li>
                </ul>
                </div>
                <div>
                <div className="bg-yellow-100/10 p-4 rounded-lg">
                  <p className="text-sm">
                    💡 <strong>Dica sobre o amarelo:</strong> Quando sua tentativa aparece em amarelo, 
                    significa que você está muito próximo! A diferença entre seu palpite e o número 
                    correto é de no máximo 2 para mais ou para menos.
                  </p>
                </div>
                <div className="bg-gray-700/50 p-3 rounded-lg mt-3">
                    <p className="text-sm text-gray-300">
                      <span className="font-semibold">📌 Sobre as setas:</span> A seta para cima (↑) indica que o número correto é maior, 
                      enquanto a seta para baixo (↓) indica que o número correto é menor.
                    </p>
                </div>
              </div>
            </div>
          </div>

          {/* Exemplo do Capítulo */}
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-blue-400">📑 Etapa 2: Capítulo</h3>
            <div className="bg-gray-700/50 p-4 rounded-lg">
              <p className="mb-4">Tente adivinhar o capítulo correto. As setas indicam se o número é maior ou menor:</p>
              
              <div className="mb-6">
                <p className="text-sm text-blue-300 mb-2">Exemplo: Buscando o capítulo 3</p>
                <div className="space-y-2">
                  <div className="bg-red-500 p-2 text-white text-sm rounded flex items-center justify-between">
                    <span>1</span>
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M5 10l7-7 7 7"></path>
                    </svg>
                  </div>
                  <div className="bg-yellow-500 p-2 text-white text-sm rounded flex items-center justify-between">
                    <span>4</span>
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M5 10l7 7 7-7"></path>
                    </svg>
                  </div>
                  <div className="bg-green-500 p-2 text-white text-sm rounded">3</div>
                </div>
                
                <div className="bg-gray-700/50 p-3 rounded-lg mt-3">
                  <p className="text-sm text-gray-300">
                    <span className="font-semibold">📌 Sobre as setas:</span> A seta para cima (↑) indica que o número correto é maior, 
                    enquanto a seta para baixo (↓) indica que o número correto é menor.
                  </p>
                </div>

                <p className="text-sm text-gray-400 mt-2">
                  🎯 Neste exemplo:
                </p>
                <ul className="text-sm text-gray-400 list-disc list-inside ml-4">
                  <li>1 é muito baixo (vermelho com seta para cima)</li>
                  <li>4 está próximo! (amarelo com seta para baixo - diferença de 1 ou 2 para menos)</li>
                  <li>3 é o número correto! (verde)</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Exemplo do Versículo */}
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-blue-400">📖 Etapa 3: Versículo</h3>
            <div className="bg-gray-700/50 p-4 rounded-lg">
              <p className="mb-4">Similar ao capítulo, você receberá dicas se o número do versículo está próximo:</p>
              
              <div className="mb-6">
                <p className="text-sm text-blue-300 mb-2">Exemplo: Buscando o versículo 16</p>
                <div className="space-y-2">
                  <div className="bg-red-500 p-2 text-white text-sm rounded flex items-center justify-between">
                    <span>20</span>
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M5 10l7 7 7-7"></path>
                    </svg>
                  </div>
                  <div className="bg-yellow-500 p-2 text-white text-sm rounded flex items-center justify-between">
                    <span>14</span>
                    <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M5 10l7-7 7 7"></path>
                    </svg>
                  </div>
                  <div className="bg-green-500 p-2 text-white text-sm rounded">16</div>
                  <div className="bg-gray-700/50 p-3 rounded-lg mt-3">
                    <p className="text-sm text-gray-300">
                      <span className="font-semibold">📌 Sobre as setas:</span> A seta para cima (↑) indica que o número correto é maior, 
                      enquanto a seta para baixo (↓) indica que o número correto é menor.
                    </p>
                  </div>
                </div>
                <p className="text-sm text-gray-400 mt-2">
                  🎯 O amarelo indica que você está próximo (diferença de ± 2)
                </p>
              </div>
            </div>
          </div>

          {/* Exemplo da Versão */}
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-blue-400">📝 Etapa 4: Versão</h3>
            <div className="bg-gray-700/50 p-4 rounded-lg">
              <p className="mb-4">Por fim, adivinhe qual versão da Bíblia está sendo usada:</p>
              
              <div className="mb-6">
                <p className="text-sm text-blue-300 mb-2">Exemplo: Buscando a versão ARA</p>
                <div className="space-y-2">
                  <div className="bg-red-500 p-2 text-white text-sm rounded">NVI</div>
                  <div className="bg-red-500 p-2 text-white text-sm rounded">ACF</div>
                  <div className="bg-green-500 p-2 text-white text-sm rounded">ARA</div>
                </div>
                <p className="text-sm text-gray-400 mt-2">
                  🎯 Nesta etapa, as tentativas são simplesmente corretas (verde) ou incorretas (vermelho)
                </p>
              </div>
            </div>
          </div>

          <div className="bg-blue-900/30 p-6 rounded-lg mt-8">
            <h3 className="text-xl font-semibold text-blue-400 mb-4">Legenda das Cores</h3>
            <div className="grid grid-cols-3 gap-4">
              <div className="flex items-center space-x-2">
                <div className="w-4 h-4 bg-green-500 rounded"></div>
                <span>Correto ✅</span>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-4 h-4 bg-yellow-500 rounded"></div>
                <span>Próximo (±2) 🎯</span>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-4 h-4 bg-red-500 rounded"></div>
                <span>Incorreto ❌</span>
              </div>
            </div>
          </div>

          <div className="bg-gray-700/50 p-6 rounded-lg text-center">
            <p className="text-lg mb-6">
              Boa sorte e que Deus abençoe sua jornada diária com a Palavra! 🙏
            </p>

            <button
              onClick={onClose}
              className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-full transition-colors duration-200 font-semibold flex items-center mx-auto space-x-2"
            >
              <span>Fechar</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialPopup;
