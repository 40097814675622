// frontend/src/components/AdminStatistics.tsx

import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { APP_API_URL } from '../config';

interface DailyStats {
  data: string;
  acessos_logados: number;
  acessos_default: number;
  total_acessos: number;
  acertos_logados: number;
  acertos_default: number;
  total_acertos: number;
}

interface Statistics {
  acessos_por_dia: DailyStats[];
  media_acessos_diarios: number;
  acessos_hoje: {
    logados: number;
    default: number;
    total: number;
  };
  acertos_hoje: {
    logados: number;
    default: number;
    total: number;
  };
  usuario_mais_acessos: {
    user__username: string;
    total_acessos: number;
  };
  media_tentativas: {
    media_tentativas: number;
  };
}

// Adicione esta função antes do componente AdminStatistics
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1); // Adiciona 1 dia
  return date.toLocaleDateString('pt-BR');
};

export default function AdminStatistics() {
  const [statistics, setStatistics] = useState<Statistics | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${APP_API_URL}/api/admin-statistics/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Não autorizado');
        }

        const data = await response.json();
        
        const formattedData: Statistics = {
          ...data,
          acessos_por_dia: data.acessos_por_dia || [],
          usuario_mais_acessos: data.usuario_mais_acessos || null,
          media_tentativas: {
            media_tentativas: data.media_tentativas?.media_tentativas || 0
          }
        };
        
        setStatistics(formattedData);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao carregar estatísticas:', error);
        setError('Erro ao carregar estatísticas');
        setLoading(false);
      }
    };

    fetchStatistics();
  }, []);

  // Adicione esta função para calcular os itens da página atual
  const getCurrentPageItems = () => {
    if (!statistics?.acessos_por_dia) return [];
    const startIndex = (currentPage - 1) * itemsPerPage;
    return statistics.acessos_por_dia.slice(startIndex, startIndex + itemsPerPage);
  };

  // Adicione esta função para calcular o total de páginas
  const totalPages = statistics ? Math.ceil(statistics.acessos_por_dia.length / itemsPerPage) : 0;

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-900 flex justify-center items-center">
        <div className="text-red-500 text-xl">{error}</div>
      </div>
    );
  }

  if (!statistics) return null;

  return (
    <div className="min-h-screen bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-extrabold text-center text-white mb-8">
          Estatísticas
        </h2>

        {/* Cards de estatísticas gerais */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-800 rounded-lg p-6 shadow-lg"
          >
            <h3 className="text-lg font-medium text-gray-300 mb-2">Acessos Hoje</h3>
            <p className="text-3xl font-bold text-blue-500">{statistics.acessos_hoje.total || 0}</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="bg-gray-800 rounded-lg p-6 shadow-lg"
          >
            <h3 className="text-lg font-medium text-gray-300 mb-2">Acertos Hoje</h3>
            <p className="text-3xl font-bold text-green-500">{statistics.acertos_hoje.total || 0}</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-gray-800 rounded-lg p-6 shadow-lg"
          >
            <h3 className="text-lg font-medium text-gray-300 mb-2">Usuário Mais Ativo</h3>
            <p className="text-xl font-bold text-yellow-500">
              {statistics.usuario_mais_acessos?.user__username || 'Nenhum'}
              <span className="block text-sm text-gray-400">
                {statistics.usuario_mais_acessos?.total_acessos || 0} acessos
              </span>
            </p>
          </motion.div>
        </div>

        {/* Tabela de estatísticas diárias */}
        <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-700">
            <thead className="bg-gray-700">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Data
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Acessos Logados
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Acessos Anônimos
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Total Acessos
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Acertos Logados
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Acertos Anônimos
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Total Acertos
                </th>
              </tr>
            </thead>
            <tbody className="bg-gray-800 divide-y divide-gray-700">
              {getCurrentPageItems().map((dia, index) => (
                <motion.tr
                  key={dia.data}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="hover:bg-gray-700"
                >
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {formatDate(dia.data)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {dia.acessos_logados}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {dia.acessos_default}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300 font-bold">
                  {dia.total_acessos}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {dia.acertos_logados}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {dia.acertos_default}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300 font-bold">
                  {/* Soma de acertos logados e default */}
                  {/* Acertos default está bugado e tá sendo a metade por alguma razão, por enquanto, multiplicamos por 2 para garantir o valor correto */}
                  {dia.acertos_logados + dia.acertos_default} 
                </td>
                </motion.tr>
              ))}
            </tbody>
          </table>

          {/* Adicione os controles de paginação após a tabela */}
          <div className="mt-4 flex justify-center items-center space-x-4">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-blue-600 text-white rounded-md disabled:bg-gray-600 disabled:cursor-not-allowed"
            >
              Anterior
            </button>
            
            <span className="text-gray-300">
              Página {currentPage} de {totalPages}
            </span>

            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-blue-600 text-white rounded-md disabled:bg-gray-600 disabled:cursor-not-allowed"
            >
              Próxima
            </button>
          </div>
        </div>

        {/* Média de acessos e tentativas */}
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
            <h3 className="text-lg font-medium text-gray-300 mb-4">Média de Acessos por Dia</h3>
            <p className="text-2xl font-bold text-blue-500">
              {statistics.media_acessos_diarios}
            </p>
          </div>

          <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
            <h3 className="text-lg font-medium text-gray-300 mb-4">Média de Tentativas por Partida</h3>
            <p className="text-2xl font-bold text-blue-500">
              {(statistics.media_tentativas?.media_tentativas || 0).toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}