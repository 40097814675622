import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
if (!container) {
  throw new Error('O elemento #root não foi encontrado no HTML.');
}

// Adiciona meta tag para prevenir tradução automática
const metaNoTranslate = document.createElement('meta');
metaNoTranslate.name = 'google';
metaNoTranslate.content = 'notranslate';
document.head.appendChild(metaNoTranslate);

const root = createRoot(container); // Cria a raiz usando createRoot

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
