import React from 'react';
import LogoDailyBible from '../assets/Logo_DailyBible_Sem_Fundo.png';

interface ShareImageCardProps {
  attempts: {
    book: number;
    chapter: number;
    verse: number;
    version: number;
  };
  totalAttempts: number;
  streak?: number;
}

const ShareImageCard: React.FC<ShareImageCardProps> = ({ attempts, totalAttempts, streak }) => {
  return (
    <div className="w-[1080px] h-[1920px] bg-gradient-to-br from-[#1a1a1a] via-[#1a365d] to-[#2d1810] p-20 flex flex-col items-center justify-center relative overflow-hidden">

      {/* Efeito de brilho sutil */}
      <div className="absolute inset-0 bg-gradient-to-t from-transparent via-blue-500/10 to-transparent" />
      
      {/* Logo */}
      <div className="mb-16 w-96 relative">
        <img 
          src={LogoDailyBible} 
          alt="DailyBible" 
          className="w-full h-auto drop-shadow-[0_0_15px_rgba(255,255,255,0.3)]"
        />
      </div>

      {/* Data */}
      <div className="mb-16">
        <p className="text-4xl text-blue-200">{new Date().toLocaleDateString('pt-BR')}</p>
      </div>

    {/* Resultado Principal */}
    <div className="mb-16 text-center">
        <p className="text-5xl font-bold text-[#e6d5cc] mb-8">
            Completei o versículo do dia em
        </p>
        <div className="relative">
            <svg width="100%" height="100">
            <defs>
                <linearGradient id="textGradient" gradientTransform="rotate(90)">
                <stop offset="0%" stopColor="#1E90FF" /> {/* Cor inicial do gradiente */}
                <stop offset="100%" stopColor="#c98d6b" /> {/* Cor final do gradiente */}
                </linearGradient>
            </defs>
            <text
                x="50%"
                y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="80"
                fontWeight="bold"
                fill="url(#textGradient)"
            >
                {totalAttempts}
            </text>
            </svg>
        </div>
        <p className="text-5xl font-bold text-[#e6d5cc]">
            tentativas!
        </p>
    </div>


      {/* Barras de Progresso */}
      <div className="w-full max-w-2xl space-y-8 mb-24 relative">
        <div className="absolute inset-0 bg-black/20 -m-8 rounded-3xl blur-xl -z-10" />
        {Object.entries(attempts).map(([key, value]) => {
          const maxAttempts = Math.max(...Object.values(attempts));
          const labels = {
            book: '📚 Livro',
            chapter: '📑 Capítulo',
            verse: '📖 Versículo',
            version: '📝 Versão'
          };
          
          return (
            <div key={key} className="w-full">
              <div className="flex justify-between items-center mb-4">
                <span className="text-3xl text-[#e6d5cc]">{labels[key as keyof typeof labels]}</span>
                <span className="text-3xl text-blue-400">{value}</span>
              </div>
              <div className="h-4 bg-[#1a365d]/50 rounded-full overflow-hidden shadow-inner">
                <div 
                  className="h-full bg-gradient-to-r from-blue-500 to-[#c98d6b] rounded-full transition-all duration-500"
                  style={{ width: `${(value / maxAttempts) * 100}%` }}
                />
              </div>
            </div>
          );
        })}
      </div>

      {/* Streak (se existir) */}
      {streak ? (
        <div className="mb-24">
          <p className="text-4xl font-bold text-[#ffd700] drop-shadow-[0_0_10px_rgba(255,215,0,0.3)]">
            🔥 Sequência: {streak} dias
          </p>
        </div>
      ) : (
        <div className="mb-24" />
      )}

    {/* Sobre o DailyBible */}
    <div className="w-full max-w-2xl mb-16 text-center relative">
        <div className="absolute inset-0 bg-black/20 -m-8 rounded-3xl blur-xl -z-10" />
        <h3 className="text-3xl font-bold text-blue-300 mb-4">
          O que é o DailyBible?
        </h3>
        <p className="text-2xl text-[#e6d5cc] leading-relaxed">
          Um jogo diário onde você descobre um versículo da Bíblia através de pistas! 
          A cada dia, um novo versículo é selecionado para você descobrir o livro, 
          capítulo, versículo e versão. 
          Venha brincar de detetive bíblico 🕵️‍♂️ e conhecer mais da Palavra de Deus! 📖✨
        </p>
    </div>

    {/* Footer */}
    <div className="mt-auto text-center">
        <p className="text-3xl text-blue-200/80 mb-4">Jogue também em:</p>
        <div className="relative">
            <svg width="200%" height="50" viewBox="0 0 500 50">
            <defs>
                <linearGradient id="footerGradient" gradientTransform="rotate(90)">
                <stop offset="0%" stopColor="#1E90FF" />
                <stop offset="100%" stopColor="#c98d6b" />
                </linearGradient>
            </defs>
            <text
                x="50%"
                y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="30"
                fontWeight="bold"
                fill="url(#footerGradient)"
            >
                https://www.dailybiblegame.com
            </text>
            </svg>
        </div>
    </div>




      {/* Marca d'água sutil */}
      {/* <div className="absolute bottom-8 right-8 opacity-30">
        <img 
          src={LogoDailyBible} 
          alt="" 
          className="w-24 h-auto"
        />
      </div> */}
    </div>
  );
};

export default ShareImageCard;