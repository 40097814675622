import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import html2canvas from 'html2canvas';
import { createRoot } from 'react-dom/client';
import ShareImageCard from './ShareImageCard';

interface ShareResultCardProps {
  attempts: {
    book: number;
    chapter: number;
    verse: number;
    version: number;
  };
  totalAttempts: number;
  streak?: number;
}

const ShareResultCard: React.FC<ShareResultCardProps> = ({ attempts, totalAttempts, streak }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);

  const generateShareText = () => {
    const date = new Date().toLocaleDateString('pt-BR');
    return `🎯 DailyBible ${date}\n\n📖 Completei o versículo do dia em ${totalAttempts} tentativas!\n\n${renderAttemptsBars()}\n${streak ? `🔥 Sequência: ${streak} dias\n\n` : '\n'}Jogue também em: https://www.dailybiblegame.com`;
  };

  const renderAttemptsBars = () => {
    const maxAttempts = Math.max(...Object.values(attempts));
    const bars = {
      book: '📚 Livro',
      chapter: '📑 Capítulo',
      verse: '📖 Versículo',
      version: '📝 Versão'
    };

    return Object.entries(attempts)
      .map(([key, value]) => {
        const filledSquares = '🟦'.repeat(value);
        const emptySquares = '⬜'.repeat(maxAttempts - value);
        return `${bars[key as keyof typeof bars]}: ${filledSquares}${emptySquares} (${value})`;
      })
      .join('\n');
  };

  const handleShare = async () => {
    const shareText = generateShareText();

    if (navigator.share) {
      try {
        await navigator.share({
          text: shareText,
        });
      } catch (err) {
        console.error('Erro ao compartilhar:', err);
        copyToClipboard(shareText);
      }
    } else {
      copyToClipboard(shareText);
    }
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('Resultado copiado para a área de transferência!');
    } catch (err) {
      console.error('Erro ao copiar:', err);
      alert('Não foi possível copiar o resultado. Por favor, tente novamente.');
    }
  };

  const generateImage = async () => {
    setIsGeneratingImage(true);
    try {
      // Criar um elemento temporário fora da tela
      const tempDiv = document.createElement('div');
      tempDiv.style.position = 'fixed';
      tempDiv.style.left = '-9999px';
      document.body.appendChild(tempDiv);

      // Renderizar o ShareImageCard
      const root = createRoot(tempDiv);
      await new Promise<void>(resolve => {
        root.render(
          <ShareImageCard
            attempts={attempts}
            totalAttempts={totalAttempts}
            streak={streak}
          />
        );
        setTimeout(resolve, 100); // Aguardar a renderização
      });

      // Capturar a imagem
      const canvas = await html2canvas(tempDiv.firstChild as HTMLElement, {
        scale: 2, // Melhor qualidade
        backgroundColor: null,
        logging: false,
      });

      // Limpar
      root.unmount();
      document.body.removeChild(tempDiv);

      // Compartilhar ou fazer download
      const blob = await new Promise<Blob>(resolve => {
        canvas.toBlob(blob => resolve(blob!), 'image/png', 1.0);
      });

      if (navigator.share && navigator.canShare({ files: [new File([blob], 'dailybible.png', { type: 'image/png' })] })) {
        await navigator.share({
          files: [new File([blob], 'dailybible.png', { type: 'image/png' })],
          title: 'Meu resultado no DailyBible',
        });
      } else {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'dailybible.png';
        a.click();
        URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.error('Erro ao gerar imagem:', err);
      alert('Não foi possível gerar a imagem. Por favor, tente novamente.');
    } finally {
      setIsGeneratingImage(false);
    }
  };

  return (
    <div className="mt-6">
      <motion.div
        ref={cardRef}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-gray-700 p-6 rounded-lg shadow-xl border border-blue-500/30"
      >
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-xl font-bold text-blue-400">Compartilhe seu resultado!</h3>
          <div className="text-sm text-gray-400">
            {new Date().toLocaleDateString('pt-BR')}
          </div>
        </div>
        
        <div className="space-y-3 mb-6">
          {Object.entries(attempts).map(([key, value]) => (
            <div key={key} className="flex items-center space-x-2">
              <div className="w-24 text-gray-300">
                {key === 'book' && '📚 Livro:'}
                {key === 'chapter' && '📑 Capítulo:'}
                {key === 'verse' && '📖 Versículo:'}
                {key === 'version' && '📝 Versão:'}
              </div>
              <div className="flex-grow h-2.5 bg-gray-600 rounded-full overflow-hidden">
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: `${(value / Math.max(...Object.values(attempts))) * 100}%` }}
                  transition={{ duration: 0.5 }}
                  className="h-full bg-blue-500"
                />
              </div>
              <div className="text-gray-300 w-8 text-right">{value}</div>
            </div>
          ))}
        </div>

        <div className="flex justify-center space-x-4">
          <button
            onClick={handleShare}
            className="flex items-center space-x-2 px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-full transition-colors duration-200 shadow-lg hover:shadow-xl"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
            </svg>
            <span>Compartilhar Texto</span>
          </button>

          <button
            onClick={generateImage}
            disabled={isGeneratingImage}
            className="flex items-center space-x-2 px-6 py-3 bg-purple-600 hover:bg-purple-700 text-white rounded-full transition-colors duration-200 shadow-lg hover:shadow-xl disabled:opacity-50"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
            </svg>
            <span>{isGeneratingImage ? 'Gerando...' : 'Compartilhar Imagem'}</span>
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default ShareResultCard; 