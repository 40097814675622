import React, { useEffect, useState, useRef } from 'react';
import { Route, Routes, Navigate, useLocation, Link, useNavigate } from 'react-router-dom';
import Game from './components/Game';
import Ranking from './components/Ranking';
import History from './components/History';
import ChooseNickname from './components/ChooseNickname';
import axios from 'axios';
import ConfirmationModal from './components/ConfirmationModal';
import { DEFAULT_ACCESS_TOKEN } from './config';
import AdminStatistics from './components/AdminStatistics';
import { Tooltip } from 'react-tooltip';
import Footer from './components/Footer';
import About from './components/About';
import EmailPopup from './components/EmailPopup';
import logoImage from './assets/Logo_DailyBible_Sem_Fundo.png';
import tituloImage from './assets/Titulo_DailyBible_Sem_Fundo.png';
import { APP_API_URL } from './config';
import PrivacyPolicy from './pages/PrivacyPolicy';

/**
 * Interface para a resposta do token.
 */
interface TokenResponse {
  token: string;
  has_nickname: boolean;
}

/**
 * Interface para as estatísticas do usuário.
 */
interface UserStats {
  sequencia_atual: number;
}

/**
 * Propriedades para o componente de navegação.
 */
interface NavigationProps {
  username: string;
  onLogout: () => void;
  isLoggedIn: boolean;
  isSuperuser: boolean;
}

/**
 * Componente de Navegação.
 * @param props - Propriedades do componente.
 * @returns Elemento JSX da navegação.
 */
function Navigation({ username, onLogout, isLoggedIn, isSuperuser }: NavigationProps) {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [userStats, setUserStats] = useState<UserStats | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const mobileMenuRef = useRef<HTMLDivElement>(null);
  const mobileMenuButtonRef = useRef<HTMLButtonElement>(null);
  const userMenuRef = useRef<HTMLDivElement>(null);
  const userButtonRef = useRef<HTMLButtonElement>(null);

  /**
   * Retorna a classe CSS para o link de navegação ativo.
   * @param path - Caminho atual.
   * @returns Classe CSS como string.
   */
  const getNavLinkClass = (path: string) => {
    return location.pathname === path
      ? "border-blue-500 text-white"
      : "border-transparent text-gray-300 hover:border-gray-700 hover:text-gray-200";
  };

  /**
   * Lida com o clique no botão de logout.
   */
  const handleLogoutClick = () => {
    setShowLogoutConfirmation(true);
    setShowUserMenu(false);
  };

  /**
   * Confirma o logout do usuário.
   */
  const handleLogoutConfirm = () => {
    setShowLogoutConfirmation(false);
    onLogout();
  };

  /**
   * Cancela o logout do usuário.
   */
  const handleLogoutCancel = () => {
    setShowLogoutConfirmation(false);
  };

  useEffect(() => {
    /**
     * Fecha o menu do usuário se clicar fora dele.
     * @param event - Evento de mouse.
     */
    function handleClickOutside(event: MouseEvent) {
      if (
        mobileMenuRef.current && 
        !mobileMenuRef.current.contains(event.target as Node) &&
        !mobileMenuButtonRef.current?.contains(event.target as Node)
      ) {
        setShowMobileMenu(false);
      }

      if (
        userMenuRef.current && 
        !userMenuRef.current.contains(event.target as Node) &&
        !userButtonRef.current?.contains(event.target as Node)
      ) {
        setShowUserMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /**
   * Redireciona o usuário para a página de login do Google.
   */
  const handleLoginRedirect = () => {
    window.location.href = `${APP_API_URL}/accounts/google/login/?process=login`;
  };

  useEffect(() => {
    /**
     * Busca as estatísticas do usuário.
     */
    const fetchUserStats = async () => {
      if (isLoggedIn) {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${APP_API_URL}/api/user/stats/`, {
            headers: {
              'Authorization': `Token ${token}`
            }
          });
          const data = await response.json();
          setUserStats(data);
        } catch (error) {
          console.error('Erro ao buscar estatísticas do usuário:', error);
        }
      }
    };

    fetchUserStats();
  }, [isLoggedIn]);

  return (
    <nav className="bg-gray-900 shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="sm:hidden flex items-center">
            <button
              ref={mobileMenuButtonRef}
              onClick={() => setShowMobileMenu(!showMobileMenu)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Abrir menu principal</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={showMobileMenu ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                />
              </svg>
            </button>
          </div>

          <div className="flex">
            <div className="flex-shrink-0 flex items-center relative">
              <button 
                onClick={() => window.location.href = '/'}
                className="flex-shrink-0 flex items-center relative hover:opacity-80 transition-opacity duration-200"
                style={{ width: 'fit-content', height: 'fit-content' }}
              >
                <img 
                  src={tituloImage} 
                  alt="DailyBible Logo" 
                  className="w-28 h-28 mr-2 ml-1 translate-y-1"
                />
                <img 
                  src={logoImage} 
                  alt="DailyBible Logo" 
                  className="w-7 h-7 mr-2 ml-1"
                />
              </button>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                <Link to="/game" className={`${getNavLinkClass('/game')} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                  Jogar
                </Link>
                <Link to="/ranking" className={`${getNavLinkClass('/ranking')} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                  Ranking
                </Link>
                {isSuperuser && (
                  <Link to="/admin" className={`${getNavLinkClass('/admin')} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                    Estatísticas
                  </Link>
                )}
                <Link to="/about" className={`${getNavLinkClass('/about')} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                  Sobre
                </Link>
              </div>
            </div>
          </div>

          <div className="flex items-center">
            {isLoggedIn ? (
              <div className="flex items-center space-x-4">
                {userStats && userStats.sequencia_atual > 0 && (
                  <div className="flex items-center text-orange-500">
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-6 w-6" 
                      viewBox="0 0 20 20" 
                      fill="currentColor"
                      data-tooltip-id="streak-tooltip"
                      data-tooltip-content="Streak atual (sequência de vitórias diárias consecutivas)"
                    >
                      <path fillRule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clipRule="evenodd"/>
                    </svg>
                    <span className="ml-1 font-bold">{userStats.sequencia_atual}</span>
                    <Tooltip id="streak-tooltip" />
                  </div>
                )}
                <div className="relative" id="user-menu">
                  <button
                    ref={userButtonRef}
                    onClick={() => setShowUserMenu(!showUserMenu)}
                    className="flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span className="sr-only">Abrir menu do usuário</span>
                    <div className="flex items-center bg-white rounded-full overflow-hidden border border-gray-300">
                      <span className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium">
                        {username.charAt(0).toUpperCase()}
                      </span>
                      <span className="px-3 py-1 text-gray-700 font-bold">{username}</span>
                    </div>
                  </button>
                  {showUserMenu && (
                    <div 
                      ref={userMenuRef}
                      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <button
                        onClick={handleLogoutClick}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                      >
                        Sair
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <button
                onClick={handleLoginRedirect}
                className="bg-white text-gray-800 hover:bg-gray-100 px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 shadow-md hover:shadow-lg"
              >
                Fazer login com Google
              </button>
            )}
          </div>
        </div>
      </div>

      <div 
        ref={mobileMenuRef}
        className={`${showMobileMenu ? 'block' : 'hidden'} sm:hidden bg-gray-800`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Link
            to="/game"
            className={`${location.pathname === '/game' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'} block px-3 py-2 rounded-md text-base font-medium`}
            onClick={() => setShowMobileMenu(false)}
          >
            Jogar
          </Link>
          <Link
            to="/ranking"
            className={`${location.pathname === '/ranking' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'} block px-3 py-2 rounded-md text-base font-medium`}
            onClick={() => setShowMobileMenu(false)}
          >
            Ranking
          </Link>
          {isSuperuser && (
            <Link
              to="/admin"
              className={`${location.pathname === '/admin' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'} block px-3 py-2 rounded-md text-base font-medium`}
              onClick={() => setShowMobileMenu(false)}
            >
              Estatísticas
            </Link>
          )}
          <Link
            to="/about"
            className={`${location.pathname === '/about' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'} block px-3 py-2 rounded-md text-base font-medium`}
            onClick={() => setShowMobileMenu(false)}
          >
            Sobre
          </Link>
        </div>
      </div>

      <ConfirmationModal
        isOpen={showLogoutConfirmation}
        onClose={handleLogoutCancel}
        onConfirm={handleLogoutConfirm}
        message="Tem certeza que deseja sair?"
      />
    </nav>
  );
}

/**
 * Componente principal do aplicativo.
 * Gerencia a autenticação, rotas e estado global.
 * @returns Elemento JSX do aplicativo.
 */
export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [showChooseNickname, setShowChooseNickname] = useState(false);
  const navigate = useNavigate();
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);

  const openEmailPopup = () => {
    setIsEmailPopupOpen(true);
  };

  const closeEmailPopup = () => {
    setIsEmailPopupOpen(false);
  };

  useEffect(() => {
    /**
     * Verifica token de autenticação e atualiza o estado do usuário.
     */
    const token = localStorage.getItem('token') || DEFAULT_ACCESS_TOKEN;
    if (localStorage.getItem('token')) {
      setIsAuthenticated(true);
      fetchUsername(token!);
      const hasNickname = localStorage.getItem('has_nickname') === 'true';
      setShowChooseNickname(!hasNickname);
      
      // Registra o acesso do usuário logado
      registerLoggedAccess(token!);
      
    } else {
      setIsAuthenticated(false);
      if (DEFAULT_ACCESS_TOKEN) {
        registerAccess(DEFAULT_ACCESS_TOKEN);
        sessionStorage.setItem('anonymous_access_registered', 'true');
      }
    }
  }, []);

  /**
   * Registra o acesso anônimo.
   * @param token - Token de acesso.
   */
  const registerAccess = async (token: string) => {
    try {
      await axios.post(`${APP_API_URL}/api/register-access/`, {}, {
        headers: { Authorization: `Token ${token}` }
      });
    } catch (error: any) {
      console.error('Erro ao registrar acesso:', error);
      if (error.response) {
        console.error('Detalhes do erro:', {
          data: error.response.data,
          status: error.response.status,
          headers: error.response.headers
        });
      }
    }
  };

  /**
   * Registra o acesso do usuário logado.
   * @param token - Token de acesso.
   */
  const registerLoggedAccess = async (token: string) => {
    try {
      await axios.post(`${APP_API_URL}/api/register-logged-access/`, {}, {
        headers: { Authorization: `Token ${token}` }
      });
    } catch (error: any) {
      console.error('Erro ao registrar acesso:', error);
      if (error.response) {
        console.error('Detalhes do erro:', {
          data: error.response.data,
          status: error.response.status,
          headers: error.response.headers
        });
      }
    }
  };

  /**
   * Busca o nome de usuário a partir do token.
   * @param token - Token de acesso.
   */
  const fetchUsername = async (token: string) => {
    try {
      const response = await axios.get<{ username: string; email: string; profile: { has_nickname: boolean } }>(`${APP_API_URL}/api/user/`, {
        headers: { Authorization: `Token ${token}` }
      });
      if (response.data && 'username' in response.data) {
        setUsername(response.data.username);
        setShowChooseNickname(!response.data.profile.has_nickname);
      }
    } catch (error) {
      console.error('Falha ao buscar o nome do usuário:', error);
      setIsAuthenticated(false);
      setUsername('');
      setShowChooseNickname(false);
    }
  };

  /**
   * Lida com a escolha do apelido pelo usuário.
   * @param newNickname - Novo apelido escolhido.
   */
  const handleNicknameChosen = (newNickname: string) => {
    setUsername(newNickname);
    setShowChooseNickname(false);
    localStorage.setItem('has_nickname', 'true');
  };

  /**
   * Lida com o logout do usuário.
   */
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('has_nickname');
    setIsAuthenticated(false);
    setUsername('');
    setShowChooseNickname(false);

    // Redirecionar para /game e recarregar a página
    navigate('/game');
    window.location.reload();
  };

  useEffect(() => {
    /**
     * Verifica se o usuário é superusuário.
     */
    const checkSuperuser = async () => {
      if (isAuthenticated) {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${APP_API_URL}/api/check-superuser/`, {
            headers: {
              'Authorization': `Token ${token}`
            }
          });
          const data = await response.json();
          setIsSuperuser(data.is_superuser);
        } catch (error) {
          console.error('Erro ao verificar superuser:', error);
        }
      }
    };

    checkSuperuser();
  }, [isAuthenticated]);

  return (
    <div className="min-h-screen bg-black flex flex-col">
      <Navigation 
        username={username} 
        onLogout={handleLogout} 
        isLoggedIn={isAuthenticated}
        isSuperuser={isSuperuser}
      />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Navigate to="/game" replace />} />
          <Route path="/auth" element={<AuthHandler />} />
          <Route path="/login" element={<AuthHandler />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/game" element={<Game />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/choose-nickname" element={isAuthenticated ? <ChooseNickname onNicknameChosen={handleNicknameChosen} onLogout={handleLogout} /> : <Navigate to="/game" replace />} />
          <Route 
            path="/admin" 
            element={isSuperuser ? <AdminStatistics /> : <Navigate to="/game" replace />} 
          />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Navigate to="/game" replace />} />
        </Routes>
      </main>
      <Footer openEmailPopup={openEmailPopup} />
      <EmailPopup isOpen={isEmailPopupOpen} onClose={closeEmailPopup} />
    </div>
  );
}

/**
 * Componente para lidar com a autenticação.
 * Redireciona o usuário após login.
 * @returns Null.
 */
function AuthHandler() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const hasNickname = params.get('has_nickname');
    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem('has_nickname', hasNickname || 'false');
      if (hasNickname?.toLowerCase() === 'true') {
        navigate('/game');
      } else {
        navigate('/choose-nickname');
      }
    } else {
      navigate('/game');
    }
  }, [location, navigate]);

  return null;
}
