import React from 'react';

interface ScoreSystemModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ScoreSystemModal({ isOpen, onClose }: ScoreSystemModalProps) {
  if (!isOpen) return null;

  // Função para lidar com cliques no backdrop
  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Verifica se o clique foi no backdrop (não no conteúdo do modal)
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    // Adicionado onClick no div exterior
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4"
      onClick={handleBackdropClick}
    >
      <div className="bg-gray-800 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-white">Sistema de Pontuação</h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="space-y-6 text-gray-300">
            <section>
              <h3 className="text-xl font-semibold mb-3 text-white">Pontuação Base</h3>
              <p className="mb-2">A pontuação base é calculada com base no número de tentativas:</p>
              <ul className="list-disc list-inside ml-4 space-y-1">
                <li>Pontuação inicial: 100 pontos</li>
                <li>Dedução por tentativa: -5 pontos</li>
                <li>Pontuação mínima garantida: 10 pontos</li>
              </ul>
              <p className="mt-2 text-sm text-gray-400">
                Exemplo: Com 4 tentativas, a pontuação base seria: 100 - (4 × 5) = 80 pontos
              </p>
            </section>

            <section>
              <h3 className="text-xl font-semibold mb-3 text-white">Bônus de Sequência</h3>
              <p className="mb-2">Jogadores recebem bônus por dias consecutivos jogando:</p>
              <ul className="list-disc list-inside ml-4 space-y-1">
                <li>+10 pontos por dia consecutivo</li>
                <li>Máximo de 100 pontos de bônus</li>
                <li>A sequência é perdida se um dia for perdido</li>
              </ul>
            </section>

            <section>
              <h3 className="text-xl font-semibold mb-3 text-white">Bônus de Experiência</h3>
              <p className="mb-2">Bônus adicional baseado no total de dias jogados:</p>
              <ul className="list-disc list-inside ml-4 space-y-1">
                <li>+5 pontos por dia jogado</li>
                <li>Máximo de 100 pontos de bônus</li>
                <li>Este bônus é permanente</li>
              </ul>
            </section>

            <section>
              <h3 className="text-xl font-semibold mb-3 text-white">Exemplo de Cálculo</h3>
              <div className="bg-gray-700 p-4 rounded-lg">
                <p className="mb-2">Para um jogador que:</p>
                <ul className="list-disc list-inside ml-4 space-y-1">
                  <li>Usou 3 tentativas (85 pontos base)</li>
                  <li>Está jogando há 5 dias seguidos (50 pontos de bônus)</li>
                  <li>Tem 10 dias totais jogados (50 pontos de bônus)</li>
                </ul>
                <p className="mt-2 font-semibold text-white">
                  Pontuação Total: 85 + 50 + 50 = 185 pontos
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
} 