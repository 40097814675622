import React from 'react';
import { motion } from 'framer-motion';
import logoImage from '../assets/Logo_DailyBible_Sem_Fundo.png';

export default function About() {
  return (
    <div className="min-h-screen bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gray-800 rounded-lg shadow-lg overflow-hidden"
        >
          <div className="p-8">
            {/* Cabeçalho com logo */}
            <div className="flex flex-col items-center mb-8">
              <motion.img
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.2 }}
                src={logoImage}
                alt="DailyBible Logo"
                className="w-40 h-40 mb-4"
              />
              <h1 className="text-3xl font-bold text-white">Bem-vindo ao DailyBible! 📖✨</h1>
            </div>

            {/* Conteúdo */}
            <div className="space-y-6 text-gray-300 leading-relaxed">
              <motion.p
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3 }}
                className="text-lg"
              >
                O <span className="text-blue-400 font-semibold">DailyBible</span> é uma experiência única que combina conhecimento bíblico e diversão! Todos os dias, um novo versículo é selecionado, e você é desafiado a descobri-lo através de um jogo envolvente. 🎯
              </motion.p>

              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4 }}
                className="bg-gray-700 p-6 rounded-lg"
              >
                <h2 className="text-xl font-semibold text-white mb-3">Como Funciona? 🤔</h2>
                <p className="text-lg">
                  Em uma série de etapas desafiadoras, você tentará identificar:
                  <ul className="list-disc list-inside mt-2 space-y-2">
                    <li>O livro da Bíblia</li>
                    <li>O capítulo</li>
                    <li>O versículo</li>
                    <li>A versão da Bíblia</li>
                  </ul>
                  Ao errar, você pode receber dicas, mas se eu fosse você eu tentaria não errar 😁, pois quanto menos tentativas você usar, mais pontos você ganha! 🏆
                </p>
              </motion.div>

              <motion.p
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5 }}
                className="text-lg"
              >
                Este projeto nasceu da ideia de unir tecnologia e fé de uma maneira interativa e educativa. Desenvolvido por{' '}
                <a 
                  href="https://github.com/Thales-Melo" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-blue-400 font-semibold hover:text-blue-300 transition-colors duration-200"
                >
                  Thales Melo
                </a>, o{' '}
                <span className="text-blue-400 font-semibold">DailyBible</span> busca tornar o estudo da Palavra de Deus mais dinâmico e envolvente para todos. 🙏
              </motion.p>

              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.6 }}
                className="bg-gray-700 p-6 rounded-lg border-l-4 border-yellow-500"
              >
                <h2 className="text-xl font-semibold text-white mb-3">Lembre-se! ⚡</h2>
                <p className="text-lg italic">
                  O <span className="text-blue-400 font-semibold">DailyBible</span> é ótimo, mas não substitui o seu devocional! Pense no DailyBible como aquele aquecimento espiritual, um treino rápido antes do grande encontro com Deus em seu estudo pessoal. Então, depois de brincar de detetive bíblico 🕵️‍♂️, não se esqueça de reservar um tempo para aprofundar-se na Palavra! 📜
                </p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.7 }}
                className="text-center mt-6"
              >
                <p className="text-lg">
                  Pronto para começar? Venha descobrir um novo versículo todos os dias! 🌟
                </p>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
} 