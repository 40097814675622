import React, { useRef, useEffect } from 'react';

interface PrivacyPolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ isOpen, onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div ref={modalRef} className="bg-gray-800 p-6 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto relative">
        <button 
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-300"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <h2 className="text-2xl font-bold mb-4 text-white">Política de Privacidade</h2>

        <div className="space-y-4 text-gray-300">
          <section>
            <h3 className="text-xl font-semibold mb-2 text-white">1. Informações Coletadas</h3>
            <p>O DailyBible coleta apenas as seguintes informações:</p>
            <ul className="list-disc list-inside ml-4">
              <li>Nome de usuário (quando fornecido)</li>
              <li>E-mail (para autenticação via Google)</li>
              <li>Estatísticas de jogo (pontuação, sequência de acertos)</li>
            </ul>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-2 text-white">2. Uso das Informações</h3>
            <p>Utilizamos suas informações para:</p>
            <ul className="list-disc list-inside ml-4">
              <li>Manter seu progresso no jogo</li>
              <li>Gerar rankings e estatísticas</li>
              <li>Melhorar a experiência do usuário</li>
              <li>Autenticar seu acesso</li>
            </ul>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-2 text-white">3. Proteção de Dados</h3>
            <p>Seus dados são protegidos através de:</p>
            <ul className="list-disc list-inside ml-4">
              <li>Criptografia nas transmissões</li>
              <li>Acesso restrito às informações</li>
              <li>Armazenamento seguro</li>
            </ul>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-2 text-white">4. Armazenamento Local</h3>
            <p>Utilizamos armazenamento local (localStorage) apenas para:</p>
            <ul className="list-disc list-inside ml-4">
              <li>Manter sua sessão ativa</li>
              <li>Armazenar suas preferências de usuário</li>
              <li>Gerenciar seu estado de autenticação</li>
            </ul>
            <p className="mt-2 text-sm text-gray-400">
              Nota: A autenticação via Google pode utilizar seus próprios cookies, que são gerenciados pela própria Google.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-2 text-white">5. Contato</h3>
            <p>Para questões sobre privacidade, entre em contato através de:</p>
            <ul className="list-disc list-inside ml-4">
              <li>Formulário de contato no site</li>
              <li>contato.dailybible@gmail.com</li>
            </ul>
          </section>

          <p className="mt-6 text-sm text-gray-400">
            Última atualização: {new Date().toLocaleDateString()}
          </p>

          <div className="mt-6 flex justify-center">
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors duration-200"
            >
              <span>Clique para visualizar em uma nova aba</span>
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-5 w-5 ml-2" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" 
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyModal; 